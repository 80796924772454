import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import { faUser, faPhone, faLock } from '@fortawesome/pro-solid-svg-icons';

// Modules
import Window from '../../../../../../modules/window';

// Models
import Opportunity from '../../../../../../models/v2/opportunity';
import User from '../../../../../../models/v2/user';

// Base
import Button from '../../../../base/button';
import Col from '../../../../base/col';
import Row from '../../../../base/row';

// Form
import Checkbox from '../../../../form/checkbox';
import Input from '../../../../form/input';
import PasswordStrengthChecklist from '../../../../form/password_strength_checklist';

export default class Form extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    onSignUp: PropTypes.func,
    errorMessages: PropTypes.object,
    sending: PropTypes.bool,
    onBack: PropTypes.func,
    allowUserType: PropTypes.bool,
    domain: PropTypes.string,
    opportunityId: PropTypes.number,
    consentFormId: PropTypes.number,
    tokenId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      errorMessages: {},
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { opportunityId } = this.props;
    if (opportunityId) {
      const opportunity = await Opportunity.find(opportunityId);
      this.setState({ opportunity });
    }
  }

  handleInputChange = (field, value) => {
    const { user } = this.state;
    const newUser = update(user, { [field]: { $set: value } });
    this.setState({ user: newUser });
  }

  handleProfileInputChange = (field, value) => {
    const { user } = this.state;
    const newUser = update(user, { profile_attributes: { [field]: { $set: value } } });
    this.setState({ user: newUser });
  }

  handleSubmit = async (e) => {
    const { domain, opportunityId, consentFormId, onSignUp } = this.props;
    const { sending, user, opportunity } = this.state;

    e && e.preventDefault();

    // Prep a window if we've been given an opportunity id. Prevents popup block
    let myWindow;
    if (opportunity && opportunity.is_jobspikr) {
      const winId = (new Date()).getTime();
      myWindow = window.open('', winId)
    }

    if (sending) return;

    this.setState({ sending: true }, () => {
      let data = {
        user: {
          email: user.email,
          password: user.password || '',
          user_type: user.user_type,
          allow_marketing: user.allow_marketing,
          profile_attributes: user.profile_attributes,
          invitation_token: user.invitation_token,
        }
      }
      if (opportunityId) {
        data.opportunity_id = opportunityId;
      }
      if (consentFormId && user.user_type === User.CONSULTANT) {
        data.consent_form_id = consentFormId;
      }
      if (user.invitation_token) {
        data.invitation_token = user.invitation_token;
      }

      const url = domain ? `${domain}/users.json` : '/users.json';

      $.ajax({
        url,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: (response) => {
          // Track conversion event in google
          gtag('event', 'conversion', { 'send_to': 'AW-707878932/-M1tCP7KxKsBEJTAxdEC' });
          const user = response.user;

          if (user.user_type === User.COMPANY) {
            // Track conversion event in LinkedIn
            window.lintrk('track', { conversion_id: 4440881 });
          }

          if (onSignUp === undefined) {
            if (myWindow) {
              myWindow.close();
            }
            if (domain) {
              // If we have a domain, this is hosted on a 3rd party page.
              // Use OTT url to ensure the user is logged in
              Window.redirect(response.ott_location);
            } else {
              Window.redirect(response.location);
            }
          } else {
            onSignUp(response.location, user, myWindow);
          }
        },
        error: ({ responseJSON }) => {
          this.setState({
            errorMessages: (responseJSON || {}).errors || {},
            sending: false,
          }, () => {
            if (opportunityId && opportunity.is_jobspikr) {
              myWindow.close();
            }
          });
        },
      });
    });
  }

  render() {
    const { onBack, allowUserType, tokenId } = this.props;
    const { user, errorMessages, sending } = this.state;
    const userTypeData = User.userTypeData[user.user_type] || {};

    return (
      <form onSubmit={this.handleSubmit}>
        <Row noMargin>
          <Col s={12}>
            <div className='flex justify-space-between margin-bottom-5'>
              <div className='flex flex-col justify-center '>
                <div className='sm-font margin-bottom-5 bold'>
                  Hi, {userTypeData.name}.
                </div>
                {
                  allowUserType &&
                  <React.Fragment>
                    <div className='xxsm-font'>
                      <a
                        onClick={onBack}
                        className='primary-color hover-primary'
                      >
                        Or not a(n) {userTypeData.name}?
                      </a>
                    </div>
                  </React.Fragment>
                }
              </div>
              <img
                className='width-80'
                src={userTypeData.img}
              />
            </div>
          </Col>
          <Col s={12} m={6}>
            <Input {...{
              id: 'first_name',
              placeholder: 'First Name',
              value: user.profile_attributes.first_name,
              errorMessage: errorMessages['profile.first_name'],
              onChange: (_, value) => this.handleProfileInputChange('first_name', value),
            }} />
          </Col>
          <Col s={12} m={6}>
            <Input {...{
              id: 'last_name',
              placeholder: 'Last Name',
              value: user.profile_attributes.last_name,
              errorMessage: errorMessages['profile.last_name'],
              onChange: (_, value) => this.handleProfileInputChange('last_name', value),
            }} />
          </Col>
          <Col s={12}>
            <Input {...{
              id: 'signup_email',
              icon: faUser,
              placeholder: user.user_type === 'agent' ? 'Work Email' : 'Email',
              value: user.email,
              errorMessage: errorMessages['email'],
              onChange: (_, value) => this.handleInputChange('email', value),
              disabled: !!tokenId,
            }} />
          </Col>
          {
            user.user_type == 'agent' &&
            <Col s={12}>
              <Input {...{
                id: 'signup_phone',
                icon: faPhone,
                placeholder: 'Phone',
                value: user.profile_attributes.phone,
                errorMessage: errorMessages['profile.phone'],
                onChange: (_, value) => this.handleProfileInputChange('phone', value),
              }} />
            </Col>
          }
          <Col s={12}>
            <Input {...{
              id: 'signup_password',
              icon: faLock,
              placeholder: 'Password',
              value: user.password || '',
              errorMessage: errorMessages['password'],
              type: 'password',
              onChange: (_, value) => this.handleInputChange('password', value),
              className: classNames('', { 'margin-bottom-10': !errorMessages.password }),
              allowPasswordToggle: true,
            }} />
            <PasswordStrengthChecklist {...{ password: user.password || '' }} />
          </Col>
          <Col s={12} className='margin-bottom-20 xxsm-font'>
            <React.Fragment>
              By signing up, you are agreeing to our
              <a
                className='primary-color hover-dark-primary'
                target='_blank'
                href={`${user.user_type == 'agent' ? '/a' : ''}/terms_and_conditions`}
              > Terms &amp; Conditions</a>.
            </React.Fragment>
          </Col>
          <Col s={12}>
            <Checkbox
              id='allow_marketing'
              className='xxsm-font'
              checked={user.allow_marketing}
              onChange={(_, checked) => this.handleInputChange('allow_marketing', checked)}
              label='I want to stay looped in with helpful newsletters, product updates and events.'
            />
          </Col>
          <Col s={12}>
            <Button
              id='create_account'
              text='Create Account'
              onClick={this.handleSubmit}
              disabled={sending}
              block
            />
          </Col>
        </Row>
        <button type='submit' className='hide' />
      </form>
    )
  }
}