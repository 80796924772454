import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

// Models
import User from '../../../../../models/v2/user';

// Modules
import { removeEmptyKeys, deparam } from '../../../../../modules/global';
import Window from '../../../../../modules/window';

// Base
import Col from '../../../base/col';
import Row from '../../../base/row';

// Related Components
import Form from './sign_up/form';
import Google from './google';
import UserType from './sign_up/user_type';

export default class SignUp extends React.Component {
  static propTypes = {
    opportunityId: PropTypes.number,
    consentFormId: PropTypes.number,
    tokenId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    allowUserType: PropTypes.bool.isRequired,
    onChangeUserType: PropTypes.func,
    onSignUp: PropTypes.func,
    userType: PropTypes.string,
    domain: PropTypes.string,
  }

  static defaultProps = {
    onChangeUserType: () => { },
  }

  constructor(props) {
    super(props);

    const params = deparam(location.search.slice(1));
    const userType = props.userType
      ? props.userType
      : !!params.ut ? params.ut : '';

    this.state = {
      sending: false,
      user: {
        profile_attributes: {
          first_name: props.firstName,
          last_name: props.lastName,
        },
        email: props.email,
        user_type: userType,
        invitation_token: props.tokenId,
        allow_marketing: true,
      },
      errorMessages: {},
      v: params.v || 'user_type',
    }
  }

  componentDidMount() {
    const { user } = this.state;
    // If there is a user type set, skip user type step
    if (!!user.user_type) {
      this.setState({ v: 'form' });
    }
  }

  get params() {
    const { tokenId } = this.props;
    const { user } = this.state;
    return removeEmptyKeys({ ut: user.user_type, tid: tokenId }, true);
  }

  updateParams = () => {
    const currentParams = deparam(location.search.slice(1));
    const param = $.param(update(currentParams, { $merge: this.params }));
    const path = `${window.location.pathname}${param ? `?${param}` : ''}`;
    window.history.replaceState({ url: path }, '', path);
  }

  handleChangeUserType = (userType) => {
    const { onChangeUserType } = this.props;
    const { user } = this.state;
    const newUser = update(user, { user_type: { $set: userType} });
    this.setState({
      user: newUser
    }, () => {
      onChangeUserType(userType)
      this.updateParams();
    });
  }

  // If a user chooses to leave the form, switch the view back to user
  // type selection and set the value on the user to the other type
  handleBack = () => {
    const { user } = this.state;
    const userType = user.user_type === User.CONSULTANT ? User.AGENT: User.CONSULTANT;
    const newUser = update(user, { user_type: { $set: userType } });
    this.setState({
      user: newUser,
      v: 'user_type',
    }, () => this.handleChangeUserType(userType));
  }

  // If a user has signed in / up with google, need to see if they
  // are a new user or not, and if not, might need to get them to
  // choose a user type. Store the response for redirection
  handleGoogleAuth = (response) => {
    const { user } = response;
    if (user && !user.user_type) {
      // User doesn't exist
      this.setState({
        showUserType: true,
        user,
        response,
        authorizing: false,
      })
    } else {
      // User already exists, just redirect
      this.handleRedirect(response.location);
    }
  }

  // Redirect to either the requested location or back to the root path
  handleRedirect = (location) => {
    const { response } = this.state;
    // Take what's passed in, if not, then what's stored in state
    // and finally fall back to root path
    const redirectTo = location || (response || {}).location || '/';
    Window.redirect(redirectTo);
  }

  handleNext = () => {
    const { user, sending } = this.state;
    if (user.id) {
      // User has already been logged in.
      // Just need to update the user_type
      if (sending) return;

      this.setState({
        sending: true,
      }, () => {
        const data = {
          id: user.id,
          user_type: user.user_type,
        };
        (new User(data))
          .save()
          .then(() => this.handleRedirect())
          .catch((error) => Window.flash.error(error));
      });
    } else {
      this.setState({ v: 'form' });
    }
  }

  render() {
    const { allowUserType, tokenId, domain, opportunityId, consentFormId, onSignUp } = this.props;
    const { v, user, errorMessages, sending } = this.state;

    return (
      <React.Fragment>
        {
          v === 'user_type' &&
          <UserType {...{
            user,
            onChangeUserType: this.handleChangeUserType,
            onNext: this.handleNext,
          }} />
        }
        {
          v === 'form' &&
          <Form {...{
            tokenId,
            user,
            errorMessages,
            onSignUp,
            onBack: this.handleBack,
            sending,
            allowUserType,
            domain,
            opportunityId,
            consentFormId,
          }} />
        }
        {
          v !== 'user_type' && !tokenId &&
          <React.Fragment>
            <Row className='margin-top-10 margin-bottom-10'>
              <Col s={12} className='vertical-align justify-center'>
                OR
              </Col>
            </Row>
            <Row className='margin-top-10'>
              <Col s={12}>
                <div className='margin-center width-400'>
                  <Google {...{
                    text: 'signup_with',
                    onAuth: this.handleGoogleAuth,
                    userType: user.user_type,
                    opportunityId,
                  }} />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}